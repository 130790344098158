import { useFlagMe269599EnableTableCustomizationSettingsMigration } from '@generated/flags/ME-269599-enable-table-customization-settings-migration';
import { useGetBulkTablePreferenceLazyQuery } from '@generated/queries/getBulkTablePreference';
import { TablePreferenceResponseType } from '@generated/types';
import { useIncrease } from '@hooks/useIncrease';
import { useIndexedDBStore } from '@hooks/useIndexedDB';
import {
  TABLE_PREFERENCES_IDB_STORE,
  idbConfig,
} from '@hooks/useIndexedDB/config';
import { getConnection } from '@hooks/useIndexedDB/db';
import { tablePrefsAtom } from '@hooks/useTablePreference';
import {
  idbDeleteAllPrefs,
  idbGetAllPrefs,
} from '@hooks/useTablePreference/idb';
import {
  addOrUpdateSinglePrefInIndexDB,
  convertPrefsToDbFormat,
  getFormattedPrefs,
} from '@hooks/useTablePreference/util';
import { reportCustomSentryError } from '@utils/sentry';
import { useAtom } from 'jotai';
import { FC, useState } from 'react';
import { useMount } from 'react-use';

export const TablePreferenceInitializer: FC = ({ children }) => {
  const enableTableCustomizationMigration =
    useFlagMe269599EnableTableCustomizationSettingsMigration();

  const [initialized, setInitialized] = useState(
    !enableTableCustomizationMigration
  );
  const idb = useIndexedDBStore(TABLE_PREFERENCES_IDB_STORE);

  // Manage prefs in a global atom to share prefs across all instances of this hook.
  const [globalTablePrefs, setGlobalTablePrefs] = useAtom(tablePrefsAtom);

  const [refetchCount, bumpRefetchCount] = useIncrease();

  // Query to fetch table preferences for all tables from API. Only called once on initial page load.
  // Should not block initial page load. Okay to run this in background.
  //  1. Fetch all table preferences from the API.
  //  2. Store all preferences in IDB.
  //  3. Update global preferences atom in the prefs hook to pass it out to all tables.
  const [getBulkTablePreferences, { refetch }] =
    useGetBulkTablePreferenceLazyQuery({
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: async (bulkPrefRes) => {
        try {
          const updatedPrefs = bulkPrefRes?.getBulkTablePreference
            ? convertPrefsToDbFormat(
                bulkPrefRes?.getBulkTablePreference as Partial<TablePreferenceResponseType>[]
              )
            : [];

          // First, update global prefs with an exact copy of API results.
          const formattedPrefs = getFormattedPrefs(updatedPrefs);
          setGlobalTablePrefs((oldPrefs) => ({
            ...oldPrefs,
            ...formattedPrefs,
          }));

          // Next, merge the API prefs with the existing local prefs in IDB, then update
          // the global prefs with the merged version.
          if (updatedPrefs?.length) {
            await Promise.all(
              updatedPrefs.map(async (pref) => {
                await addOrUpdateSinglePrefInIndexDB(
                  idb,
                  pref,
                  globalTablePrefs,
                  (mergedPrefs) => {
                    // Update global prefs with merged prefs.
                    setGlobalTablePrefs((oldPrefs) => ({
                      ...oldPrefs,
                      ...mergedPrefs,
                    }));
                  }
                );
              })
            );
          }
        } catch (err) {
          reportCustomSentryError(
            `Error bulk updating table preferences: ${err}`
          );
        }
      },
      onError: async (error) => {
        reportCustomSentryError(
          `Error bulk fetching table preferences: ${error}`
        );
        // Retry the query
        try {
          bumpRefetchCount();
          if (refetchCount < 3) {
            await refetch();
          } else {
            reportCustomSentryError(`Table preferences - hit refetch limit`);
          }
        } catch (refetchError) {
          reportCustomSentryError(
            `Error refetching table preferences: ${refetchError}`
          );
        }
      },
    });

  useMount(() => {
    // Block the app from rendering until prefs are initialized from IDB.
    // Everything before `setInitialized()` should be as fast as possible because
    // it will delay all page loads. Call the bulk query in the background to
    // freshen up the IDB store, but do not block the UI with that.
    const initializePrefs = async (): Promise<void> => {
      try {
        await getConnection(idbConfig);

        // Load the table preferences from IDB. Quick, local operation, no API calls.
        const allPrefs = await idbGetAllPrefs(idb);

        // Update global prefs atom with IDB contents. This ensures that any stored prefs
        // are immediately available to all tables.
        setGlobalTablePrefs((oldPrefs) => ({
          ...oldPrefs,
          ...allPrefs,
        }));
      } catch (error) {
        // Report an error if anything went wrong above, but keep it a silent failure.
        reportCustomSentryError(`Failed to setup IndexDB: ${error}`);
      } finally {
        // App is ready to go.
        setInitialized(true);

        // Refresh the table preferences from the API in the background.
        // Most likely no change unless this is an empty IDB after a user login.
        if (enableTableCustomizationMigration) {
          getBulkTablePreferences();
        } else {
          idbDeleteAllPrefs(idb);
        }
      }
    };

    initializePrefs();
  });

  if (initialized) {
    return children as fixMe;
  }
  return null;
};
