import { getActionsReturnType } from '@hooks/useIndexedDB/types';
import { reportCustomSentryError } from '@utils/sentry';
import { isEmpty } from 'lodash-es';
import { AllTablePreferences, TablePreferencesShape } from '.';
import { getFormattedPrefs } from './util';

export type IDBActions = getActionsReturnType<
  TablePreferencesShape,
  TablePreferencesShape
>;

export const idbDeleteAllPrefs = async (idb: IDBActions): Promise<void> => {
  try {
    await idb.deleteAll();
  } catch (err) {
    reportCustomSentryError(
      `Error deleting table preferences in from indexDB | ${err}`
    );
  }
};

export const idbHasPrefsForTable = (
  tableId: string,
  tablePreferences?: AllTablePreferences
): boolean => {
  const tablesInDB = Object.keys(tablePreferences ?? {});
  return tablesInDB.indexOf(tableId) !== -1;
};

export const idbGetAllPrefs = async (
  idb: IDBActions
): Promise<AllTablePreferences> => {
  const allPrefs = await idb.getAll();

  return !isEmpty(allPrefs)
    ? (getFormattedPrefs(allPrefs) as AllTablePreferences)
    : {};
};

export const idbSavePrefsForTable = async (
  idb: IDBActions,
  pref: TablePreferencesShape,
  isNew = true
): Promise<TablePreferencesShape> => {
  const idbObject = {
    objectId: pref.objectId,
    tableName: pref.tableName,
    columns: pref.columns,
  } as TablePreferencesShape;

  return isNew ? await idb.add(idbObject) : await idb.update(idbObject);
};
