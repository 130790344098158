import * as TP from '../subscriptionTypes';

import { gql } from '@apollo/client';
export type MessagingNotificationStatusEventPayloadFragment = (
  { readonly __typename?: 'MessagingNotificationStatusEvent' }
  & Pick<TP.MessagingNotificationStatusEvent, 'id' | 'type' | 'status' | 'serverType' | 'errorMessage'>
);

export const MessagingNotificationStatusEventPayloadFragmentDoc = gql`
    fragment MessagingNotificationStatusEventPayload on MessagingNotificationStatusEvent {
  id
  type
  status
  serverType
  errorMessage
}
    `;