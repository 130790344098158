import {
  BUILD_VERSION,
  environment,
  IS_ELECTRON,
  IS_LOCAL_CYPRESS,
  IS_LOCAL_DEV,
  IS_LOCAL_DEV_NO_CY_NO_EMULATE,
  KEY_USER_PREFERENCES,
  KnownDomains,
  KnownTenants,
  tenantDomainArr,
  TENANT_ID_TO_NAME,
  isPRPreview,
  IS_CONNECT_CARRIER,
  EMPTY_ACCT_UUID,
} from '../utils/constants';
/* eslint-disable no-restricted-globals */
import { ENV_VARS, MODE } from '@env';
import { isString, mapValues, pick } from 'lodash-es';
import { launchDarklyKeyMap } from './launchDarklyKeyMap';
import { Exact } from '@generated/types';
import { jsonParse } from '@utils/json';
import { sendSync } from '../utils/electron';
import { win } from '../utils/win';

interface Config {
  alchemyDataEndpoint: string;
  apiEndpoint: string;
  minionApiRestEndpoint: string;
  dataDictionaryEndpoint: string;
  uploadsApiEndpoint: string;
  launchDarklyKey: string;
  keycloakUri: string;
  keycloakRealm: string;
  keycloakClientId: string;
  restApiEndpoint: string;
  subscriptionApiEndpoint: string;
  emailTenderingEndpoint: string;
  caenLockingApiEndpoint: string;
  regionsDataEndpoint: string;
  ltlPricingMatrix: string;
  flatFileLicenseKey: string;
  flatFileApiEndPoint: string;
  masterbidEndpoint: string;
  flatFilePlatformPublishableKey: string;
  flatFilePlatformEnvironmentId: string;
  /** ie chs9l-test, ml100-prod */
  envKey: string;
}

const crossAppKeys = ['apiEndpoint', 'keycloakUri', 'keycloakRealm', 'keycloakClientId', 'launchDarklyKey', 'envKey'] as const;
type CrossAppConfig = Pick<Config,
  typeof crossAppKeys[number]>;

const {
  // this is only used for the validation script, yarn config:check
  ORIGIN: originFromEnv,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} = ENV_VARS;

let origin = originFromEnv || '';
if (typeof window !== 'undefined') {
  origin = window.location.origin;
}

const getPRDomainFromStorage = (): string | undefined => {
  if (isPRPreview(win.location.hostname)) {
    if (win.localStorage?.getItem(KEY_USER_PREFERENCES)) {
      const prefs = jsonParse<{ prDomain?: string }>(
        localStorage.getItem(KEY_USER_PREFERENCES) || '{}'
      );
      return prefs?.prDomain;
    }
  }
  return;
};

export const authLogoutRedirectUri = origin;

const {
  VITE_GRAPHQL_URL: apiEndpoint = '',
  VITE_DATA_DICTIONARY_URL:
    dataDictionaryEndpoint = 'http://localhost:5321/minion',
  VITE_MINION_API_URL:
    minionApiRestEndpoint = 'http://localhost:5321/minion-api',
  VITE_UPLOADS_URL: uploadsApiEndpoint = '',
  VITE_AUTH_KEYCLOAK_REALM: keycloakRealm = 'dev.mm100.mastermindtms.com',
  VITE_AUTH_KEYCLOAK_SERVER:
    keycloakUri = 'https://id.mm100nonprod.mastermindtms.com/auth',
  VITE_AUTH_KEYCLOAK_CLIENTID:
    keycloakClientId = 'https://dev.mm100.mastermindtms.com/',
  VITE_REST_URL: restApiEndpoint = '',
  VITE_SUBSCRIPTION_ENDPOINT:
    subscriptionApiEndpoint = 'ws://localhost:5321/graphql',
  VITE_EMAIL_TENDERING_ENDPOINT:
    emailTenderingEndpoint = 'https://tendering-email-api.dev.mm100.mastermindtms.com',
  VITE_AlCHEMY_DATA_ENDPOINT:
    alchemyDataEndpoint = 'https://alchemy-data-api.dev.mm100.mastermindtms.com',
  VITE_CAEN_LOCKING_ENDPOINT:
    caenLockingApiEndpoint = 'https://caenlocking-api.dev.mm100.mastermindtms.com',
  VITE_REGIONS_DATA_ENDPOINT:
    regionsDataEndpoint = `https://regions-v2-api.dev.mm100.mastermindtms.com`,
  VITE_FLAT_FILE_LICENSE_KEY: flatFileLicenseKey = '',
  VITE_FLAT_FILE_API_END_POINT:
    flatFileApiEndPoint = `https://flatfile.dev.mm100.mastermindtms.com`,
  VITE_LTL_PRICING_MATRIX_ENDPOINT:
    ltlPricingMatrix = `https://ltl-pricing-matrix.dev.mm100.mastermindtms.com`,
  VITE_MASTERBID_ENDPOINT:
    masterbidEndpoint = `https://masterbid.dev.mm100.mastermindtms.com`,
  VITE_FLATFILE_PLATFORM_PUBLISHABLE_KEY: flatFilePlatformPublishableKey = '',
  VITE_FLATFILE_PLATFORM_ENVIRONMENT_ID: flatFilePlatformEnvironmentId = '',
} = ENV_VARS;

export const sentryDsn =
  'https://5a8f866f49f74656a8e011942fc72ab7@o291138.ingest.sentry.io/1723665';
export const mapboxBaseStyle =
  'mapbox://styles/mastery-logistics/ck5o7qc0w01uz1ijy5qwsjgku';
export const mapboxStreetStyle = 'mapbox://styles/mapbox/streets-v12';
export const mapboxSatelliteStyle =
  'mapbox://styles/mapbox/satellite-streets-v11';

let localConfig: Config = {
  apiEndpoint,
  minionApiRestEndpoint,
  dataDictionaryEndpoint,
  keycloakClientId,
  keycloakRealm,
  keycloakUri,
  launchDarklyKey: launchDarklyKeyMap.local,
  restApiEndpoint,
  subscriptionApiEndpoint,
  alchemyDataEndpoint,
  uploadsApiEndpoint,
  emailTenderingEndpoint,
  caenLockingApiEndpoint,
  regionsDataEndpoint,
  ltlPricingMatrix,
  flatFileLicenseKey,
  flatFileApiEndPoint,
  masterbidEndpoint,
  flatFilePlatformPublishableKey,
  flatFilePlatformEnvironmentId,
  envKey: 'local',
};

// Always use local Node API when running tests in cypress
// There is no need to use internal dev or the value of VITE_EMULATE_DOMAIN for cy.
if (IS_LOCAL_CYPRESS) {
  localConfig = mapValues(localConfig, (val) => {
    if (isString(val) && val.match('//')) {
      try {
        const uu = new URL(val);
        uu.host = 'localhost:5321';
        if (uu.protocol === 'https:') {
          uu.protocol = 'http:';
        }
        let str = uu.href;
        if (!val.match(/\/$/) && uu.pathname === '/') {
          str = str.replace(/\/$/, '');
        }
        return str;
      } catch {
        //
      }
    }
    return val;
  });
}

export const configAliases: Partial<Record<KnownDomains, KnownDomains[]>> = {
  'ml100.mastermindtms.com': ['molo.mastermindtms.com', 'mm.shipmolo.com'],
  'test.ml100.mastermindtms.com': [
    'sandbox.shipmolo.com',
    'upsidedown.shipmolo.com',
  ],
  'dev.mm100.mastermindtms.com': [
    'mastery-frontend.dev.mm100.mastermindtms.com',
  ],
};

const getTenantCodeFromHostname = (hostname: string): string | undefined => {
  if (hostname.match(/connect-carrier/)){
    return 'connect-carrier';
  }
  const found = Object.entries(TENANT_ID_TO_NAME).find(([, name]) =>
    new RegExp(name).test(hostname)
  )?.[0];
  if (found){
    return found;
  }
  let base = typeof hostname === 'string' ? hostname : '';
  const foundFromResolutions = Object.entries(configAliases).find(
    ([, testArr]) => testArr.includes(base as KnownDomains)
  )?.[0];
  if (foundFromResolutions) {
    base = foundFromResolutions;
  }
  return base.replace('.mastermindtms.com', '').replace('dev.', '').replace('test.', '');
};

const getEnvKeyFromHostname = (hostname: string): string => {
  if (hostname === 'localhost'){
    return hostname;
  }
  if (isPRPreview(hostname)){
    return 'pull'
  }
  const tenantCode = getTenantCodeFromHostname(hostname);
  let res = '';
  if (hostname.match(/dev\./)){
    res = `${tenantCode}-dev`;
  } else if (hostname.match(/test\./)){
    res = `${tenantCode}-test`;
  } else {
    res = `${tenantCode}-prod`;
  }
  if (tenantCode === 'connect-carrier'){
    return res.replace('connect-carrier-', '');
  }
  return res;
}

const hostnameIsV2Tenant = (hostname: string): boolean =>
  Boolean(
    Object.entries(TENANT_ID_TO_NAME).find(([, name]) =>
      new RegExp(name).test(hostname)
    )
  );

const getConnectCarrierLDKey = (base: string): string => {
  let key = launchDarklyKeyMap.production;
  if (base === 'dev'){
    key = launchDarklyKeyMap.internalDev;
  } else if (base === 'test') {
    key = launchDarklyKeyMap.internalTest;
  } else if (base.match(/uat/)) {
    key = launchDarklyKeyMap.preview;
  }
  return key;
}

export const getConnectCarrierConfig = (hostname: string): CrossAppConfig => {
  // connect-carrier.dev.hub.mastermindtms.com
  // connect-carrier.test.hub.mastermindtms.com
  // connect-carrier.uat-e.hub.mastermindtms.com
  // connect-carrier.uat-c.hub.mastermindtms.com
  // connect-carrier.hub.mastermindtms.com
  let base = hostname.replace('connect-carrier.', '').replace('hub.mastermindtms.com', '').replace('.', '')
  if (IS_LOCAL_DEV || isPRPreview(base)){
    base = 'dev';
  }
  const launchDarklyKey = getConnectCarrierLDKey(base);
  if (base){
    base = base + '.';
  }
  return {
    keycloakUri: `https://id.${base}hub.mastermindtms.com/auth`,
    keycloakClientId: `https://${base}hub.mastermindtms.com/carrier-connect`,
    keycloakRealm: `${base}hub.mastermindtms.com`,
    apiEndpoint: `https://graphql.${base}hub.mastermindtms.com/`,
    launchDarklyKey,
    envKey: getEnvKeyFromHostname(hostname),
  }
}

export const getConfigFromUrl = (hostname: string): Config | undefined => {
  let base = typeof hostname === 'string' ? hostname : '';
  const foundFromResolutions = Object.entries(configAliases).find(
    ([, testArr]) => testArr.includes(base as KnownDomains)
  )?.[0];
  if (foundFromResolutions) {
    base = foundFromResolutions;
  }
  let useV2 = false;
  const tenantCode = getTenantCodeFromHostname(hostname);
  if (hostnameIsV2Tenant(hostname)) {
    base = hostname;
    useV2 = true;
  }
  if (base === 'localhost' || IS_LOCAL_CYPRESS) {
    // If we know we are in local, on a regular browser (not cy), AND that the contributor does not want to emulate an env, we use internal dev config.
    if (IS_LOCAL_DEV_NO_CY_NO_EMULATE) {
      return getConfigFromUrl('dev.mm100.mastermindtms.com');
    }
    return localConfig;
  } else if (isPRPreview(base)) {
    return getConfigFromUrl(
      getPRDomainFromStorage() ?? 'dev.mm100.mastermindtms.com'
    );
  } else if (!base.match(/mastermindtms\.com/)) {
    return undefined;
  }
  let tenantId = base
    .replace(/dev\./, '')
    .replace(/test\./, '')
    .replace(/\.mastermindtms\.com/, '');
  if (useV2) {
    tenantId = hostname;
  }
  const isDev = Boolean(base.match(/^dev\./));
  const isTest = Boolean(base.match(/^test\./));
  const isPreview = isDev || isTest;
  let launchDarklyKey = launchDarklyKeyMap.production;
  if (tenantId === 'mm100' || tenantId === 'td100') {
    if (isDev) {
      launchDarklyKey = launchDarklyKeyMap.internalDev;
    } else {
      launchDarklyKey = launchDarklyKeyMap.internalTest;
    }
  } else if (isPreview) {
    launchDarklyKey = launchDarklyKeyMap.preview;
  }

  const FlatFilePlatformPublishableKeys: Exact<{ [key in KnownDomains]: string }> = {
    // INTERNAL DOMAINS
    'dev.mm100.mastermindtms.com': 'pk_94ba8fbf2fde4568a3c20f910b69fd83',
    'test.mm100.mastermindtms.com': 'pk_94ba8fbf2fde4568a3c20f910b69fd83',
    'mastery-frontend.dev.mm100.mastermindtms.com': 'pk_94ba8fbf2fde4568a3c20f910b69fd83',
    'test.td100.mastermindtms.com': 'pk_0c93d1db453b46af96ff772203f22540',
    'mm100.mastermindtms.com': 'pk_94ba8fbf2fde4568a3c20f910b69fd83',
    'mastery-frontend.pages.dev': 'pk_94ba8fbf2fde4568a3c20f910b69fd83',
    'azurestaticapps.net': 'pk_94ba8fbf2fde4568a3c20f910b69fd83',
    'demo.mastermindtms.com': 'pk_94ba8fbf2fde4568a3c20f910b69fd83',


    // TENANT DEV AND TEST DOMAINS
    'test.ml100.mastermindtms.com': 'pk_66bfd2945e2c40dd917e7e0f6a0dcfc9',
    'sandbox.shipmolo.com': 'pk_66bfd2945e2c40dd917e7e0f6a0dcfc9',
    'upsidedown.shipmolo.com': 'pk_66bfd2945e2c40dd917e7e0f6a0dcfc9',
    'dev.sn100.mastermindtms.com': 'pk_a211addd1ff84ad3bd52acf64dcc1ccf',
    'test.sn100.mastermindtms.com': 'pk_a211addd1ff84ad3bd52acf64dcc1ccf',
    'dev.we100.mastermindtms.com': 'pk_84535934b3614cd48875069329816239',
    'test.we100.mastermindtms.com': 'pk_84535934b3614cd48875069329816239',
    'dev.tb100.mastermindtms.com': 'pk_a3df4fc89ddb48ddb78b19e0cfe165ea',
    'test.ru100.mastermindtms.com': 'pk_5f66f323a4114b139ab183c7ae1860d6',
    'test.tm100.mastermindtms.com': 'pk_2f3e61ab4d7c4b67975f92f7d4fbd247',
    'test.pr100.mastermindtms.com': 'pk_9371cfde9e4443da9a4050c99bc5c888',
    'test.ls8fm.mastermindtms.com': 'pk_a291f27b030e4618b311bd6390dda885',
    'test.loadsmith.mastermindtms.com': 'pk_a291f27b030e4618b311bd6390dda885',
    'test.averitt.mastermindtms.com': 'pk_c9a6539877c149ba9abecadbefd3a769',
    'test.covenant.mastermindtms.com': 'pk_479e480bdd374ca5845c07bf5dd62879',
    'test.cargill.mastermindtms.com': 'pk_74877c3bb32048359c88b8cb5e0e59ba',
    'test.cat.mastermindtms.com': 'pk_4919d020472e461c9a6a0cc78860eaa3',
    'test.dollargeneral.mastermindtms.com': 'pk_32fc2f5c1ceb47eba98ef0bcdd2a09e6',
    'test.kdp.mastermindtms.com': 'pk_dfad5a6b59ec4d74a0fd6475d4e1029a',
    'test.pepsi.mastermindtms.com': 'pk_8bbcd6c3f757453691ada4fb8afa7a30',
    'test.usmmg.mastermindtms.com': 'pk_c32e83c792c84d9f963728c78c968be9',
    'test.chs.mastermindtms.com': 'pk_352fd178f66b4bbebf6e491e464fcf80',

    // TENANT PROD DOMAINS
    'ml100.mastermindtms.com': 'pk_2abf6be87da34e73953c983f538e74f9',
    'mm.shipmolo.com': 'pk_2abf6be87da34e73953c983f538e74f9',
    'molo.mastermindtms.com': 'pk_2abf6be87da34e73953c983f538e74f9',
    'ru100.mastermindtms.com': 'pk_2e6d712a30344d8383aacbc69362cd6a',
    'sn100.mastermindtms.com': 'pk_b16ddd93b537473ab137212bef191b31',
    'tb100.mastermindtms.com': 'pk_abfcd25c2a794de4a6c37215ff257f84',
    'we100.mastermindtms.com': 'pk_49202fde45074df0b902ddd3fc159370',
    'tm100.mastermindtms.com': 'pk_efc93cb6100a4873b5617ff3897a2e69',
    'pr100.mastermindtms.com': 'pk_424393b09a6f4615b54460ee3c8f533a',
    'loadsmith.mastermindtms.com': 'pk_585e5b5daadb40e09c8f54ce13ae6e1c',
    'covenant.mastermindtms.com': 'pk_29b75a97d416473aa735af3f7aa1b93c',
    'averitt.mastermindtms.com': 'pk_1372ef4479d84498a7d37a35f35c0c4c',
    'hirschbach.mastermindtms.com': 'pk_fae9d5f7c3744a52ad171ab20b06adcd',
    'evans.mastermindtms.com': 'pk_af8aa2ede879474ebc6a567b43adb607',
    'cargill.mastermindtms.com': 'pk_298336c5eedc4b1ea93b4ee92c6b4afc',
    'cat.mastermindtms.com': 'pk_fc1a869b065e4fc2b46e582950e20cf3',
    'kdp.mastermindtms.com': 'pk_8b34db3b39b443eb80d83b1e9c650215',
    'dollargeneral.mastermindtms.com': 'pk_ed13240126fb40a982115e8e5d6a1ab0',
    'pepsi.mastermindtms.com': 'pk_057c5a61506a4d3f8e8bb54f432ce0bf',
    'usmmg.mastermindtms.com': 'pk_984f9d7a94cf4d3a9fe420c5d691f6f8',
    'chs.mastermindtms.com': 'pk_2f9b7f8118c049749493bf05e1259e55',
  };

  const FlatFilePlatformEnvironmentIds: Exact<{ [key in KnownDomains]: string }> = {
    // INTERNAL DOMAINS
    'dev.mm100.mastermindtms.com': 'us_env_v2SeUhZx',
    'test.mm100.mastermindtms.com': 'us_env_v2SeUhZx',
    'mastery-frontend.dev.mm100.mastermindtms.com': 'us_env_v2SeUhZx',
    'test.td100.mastermindtms.com': 'us_env_9ENDe2Nt',
    'mm100.mastermindtms.com': 'us_env_v2SeUhZx',
    'mastery-frontend.pages.dev': 'us_env_v2SeUhZx',
    'azurestaticapps.net': 'us_env_v2SeUhZx',
    'demo.mastermindtms.com': 'us_env_v2SeUhZx',


    // TENANT DEV AND TEST DOMAINS
    'test.ml100.mastermindtms.com': 'us_env_YcYV2UZt',
    'sandbox.shipmolo.com': 'us_env_YcYV2UZt',
    'upsidedown.shipmolo.com': 'us_env_YcYV2UZt',
    'dev.sn100.mastermindtms.com': 'us_env_6oK7l78g',
    'test.sn100.mastermindtms.com': 'us_env_6oK7l78g',
    'dev.we100.mastermindtms.com': 'us_env_Y4ht7LrH',
    'test.we100.mastermindtms.com': 'us_env_Y4ht7LrH',
    'dev.tb100.mastermindtms.com': 'us_env_gLiX3giZ',
    'test.ru100.mastermindtms.com': 'us_env_2ToOqqXi',
    'test.tm100.mastermindtms.com': 'us_env_xvGZj7sX',
    'test.pr100.mastermindtms.com': 'us_env_he5iLkUN',
    'test.ls8fm.mastermindtms.com': 'us_env_0L8sIPGt',
    'test.loadsmith.mastermindtms.com': 'us_env_0L8sIPGt',
    'test.averitt.mastermindtms.com': 'us_env_vlhcGzZW',
    'test.covenant.mastermindtms.com': 'us_env_vg2oeXm4',
    'test.cargill.mastermindtms.com': 'us_env_VRJvFpQ8',
    'test.cat.mastermindtms.com': 'us_env_5DQMI9Bh',
    'test.dollargeneral.mastermindtms.com': 'us_env_PPbaZqj3',
    'test.kdp.mastermindtms.com': 'us_env_1zdbgX0S',
    'test.pepsi.mastermindtms.com': 'us_env_129u1W2w',
    'test.usmmg.mastermindtms.com': 'us_env_bdw1AYMX',
    'test.chs.mastermindtms.com': 'us_env_97NEpx7l',

    // TENANT PROD DOMAINS
    'ml100.mastermindtms.com': 'us_env_02r4NbQy',
    'mm.shipmolo.com': 'us_env_02r4NbQy',
    'molo.mastermindtms.com': 'us_env_02r4NbQy',
    'ru100.mastermindtms.com': 'us_env_lroyjjB8',
    'sn100.mastermindtms.com': 'us_env_RcObW66u',
    'tb100.mastermindtms.com': 'us_env_2HIOqcCu',
    'we100.mastermindtms.com': 'us_env_GO44mgHF',
    'tm100.mastermindtms.com': 'us_env_TC37WovJ',
    'pr100.mastermindtms.com': 'us_env_JoiAQidb',
    'loadsmith.mastermindtms.com': 'us_env_SNlMLO0F',
    'covenant.mastermindtms.com': 'us_env_Q9Ws0YLG',
    'averitt.mastermindtms.com': 'us_env_Xe8u8epU',
    'hirschbach.mastermindtms.com': 'us_env_3CSt2UNl',
    'evans.mastermindtms.com': 'us_env_yGee7FKA',
    'cargill.mastermindtms.com': 'us_env_hU5tiRAr',
    'cat.mastermindtms.com': 'us_env_Yv1h06H9',
    'kdp.mastermindtms.com': 'us_env_FwFahtj0',
    'dollargeneral.mastermindtms.com': 'us_env_RsqOI6Py',
    'pepsi.mastermindtms.com': 'us_env_hAWpjlV4',
    'usmmg.mastermindtms.com': 'us_env_07EFCAfa',
    'chs.mastermindtms.com': 'us_env_IX8hPiMv'
  };

  const flatFileLicenseKey =
    FlatFileLicenseKeys[tenantId as KnownTenants] ||
    FlatFileLicenseKeys[tenantCode as KnownTenants] ||
    '';


  const flatFilePlatformPublishableKey =  isPRPreview(hostname) ?
                                          FlatFilePlatformPublishableKeys['dev.mm100.mastermindtms.com']
                                          : FlatFilePlatformPublishableKeys[hostname as KnownDomains] || '';
  const flatFilePlatformEnvironmentId =  isPRPreview(hostname) ?
                                          FlatFilePlatformEnvironmentIds['dev.mm100.mastermindtms.com']
                                          : FlatFilePlatformEnvironmentIds[hostname as KnownDomains] || '';

  let keycloakUri = `https://id.${tenantId}${
    isPreview ? 'non' : ''
  }prod.mastermindtms.com/auth`;

  if (useV2) {
    const kcBase = base
      .replace(/dev\./, '')
      .replace(/test\./, '')
      .replace(/\.mastermindtms\.com/, '');
    keycloakUri = `https://id.${kcBase}${
      isPreview ? '-nonprod' : ''
    }.mastermindtms.com/auth`;
  }

  const obj = {
    launchDarklyKey,
    apiEndpoint: `https://graphql.${base}/`,
    minionApiRestEndpoint: `https://minion-api.${base}`,
    dataDictionaryEndpoint: `https://minion-api.${base}/graphql`,
    subscriptionApiEndpoint: `wss://subscriptions.${base}/graphql`,
    uploadsApiEndpoint: `https://uploads.${base}`,
    restApiEndpoint: `https://rest.${base}`,
    keycloakUri,
    keycloakClientId: `https://${base}/`,
    keycloakRealm: base,
    caenLockingApiEndpoint: `https://caenlocking-api.${base}`,
    emailTenderingEndpoint: `https://tendering-email-api.${base}`,
    alchemyDataEndpoint: `https://alchemy-data-api.${base}`,
    regionsDataEndpoint: `https://regions-v2-api.${base}`,
    flatFileLicenseKey,
    flatFileApiEndPoint: `https://flatfile.${base}`,
    ltlPricingMatrix: `https://ltl-pricing-matrix.${base}`,
    masterbidEndpoint: `https://masterbid.${base}`,
    flatFilePlatformPublishableKey,
    flatFilePlatformEnvironmentId,
    envKey: getEnvKeyFromHostname(hostname) ?? '',
  };
  if (tenantId === 'sn100' && isPreview) {
    obj.keycloakUri = 'https://id.clientnonprod.mastermindtms.com/auth';
  }
  return obj;
};

// TODO: FlatFile license keys for all tenants
const FlatFileLicenseKeys: Exact<{ [key in KnownTenants]: UUID }> = {
  mm100: '70cbe3fa-b6ba-4943-be91-c2b93060c496',
  sn100: '90c6e4fe-8f73-4957-b5cf-9e4a86a232f3',
  ml100: '14499380-d8b8-43b8-9108-df4623a59cde',
  tb100: 'afcdf284-6790-4bb2-9ff5-6ad71e99b8de',
  we100: '9a3b33e2-6d7b-4c6c-82cc-79fe7af5cad8',
  ru100: '2e6c4dcc-429a-4413-ba15-578b2fcd562c',
  td100: '70cbe3fa-b6ba-4943-be91-c2b93060c496',
  tm100: 'e030017c-593f-49ae-8500-900f29d38255',
  pr100: '21ee849d-6b8d-48d0-b036-f89eb7b60799',
  ls8fm: '9275091f-b12c-42ad-abda-87a519c228ca',
  ave4u: '1c8e377d-83c8-41ad-9663-567ce868e9e8',
  // kbx84: '563c799a-d237-477c-aad1-3d6c8e079268',
  co2yt: '4efb98af-d075-43e4-b6cd-941ea3854d81',
  cpg: '82e7aad7-7b30-4193-aedb-1b049440c45d',
  cgl60: '2447f3ef-9763-4e3d-97bc-a0b5c2d7ef8b',
  eva9o: 'cd04a10e-324b-485d-93bf-7f8108be0d7a',
  hir3j: 'be91054c-bf19-445d-b8f5-2ca459808bf1',
  cat7x: '19eafb0a-81d4-4ea1-921a-26772d606d45',
  dg3nl: '61a2639f-3e08-416a-89cc-1ddfa9f92c67',
  kdp5t: '6674d3bf-296e-41a9-bd34-a0909806dca7',
  pep5c: '3bf9db91-4ddf-4165-9f01-e834c26c97c6',
  mdem0: '43858bc8-52d8-4a5e-98b2-8cc49342ed6b',
  usmmg: 'ee8cd5cf-15bb-4bd5-9274-ad117a207b9a',
  // flatfile v1 not available for these tenants
  chs9l: EMPTY_ACCT_UUID
};

/* istanbul ignore next */
export const getKnownDomainFromInput = (
  rawStr: string | undefined
): string | undefined => {
  if (!rawStr) {
    return;
  }
  let str = rawStr;
  try {
    str = new URL(str).hostname;
  } catch {
    // noop
  }
  if (IS_LOCAL_DEV && str === 'localhost') {
    return str;
  }
  return tenantDomainArr.find((d) => d === str) ?? str;
};

let { hostname } = win.location;
hostname = getPRDomainFromStorage() || hostname;

let ELECTRON_DOMAIN: undefined | string = sendSync('store.get', 'space');
if ((ELECTRON_DOMAIN || '').match('localhost')) {
  ELECTRON_DOMAIN = undefined;
}
// This only happens when there is no space selected, and the user is presented with selection screen. We are only satisfying the rawConfig requirement, so it does not throw error.
if (IS_ELECTRON && !ELECTRON_DOMAIN && hostname === '-') {
  ELECTRON_DOMAIN = 'dev.mm100.mastermindtms.com';
}

let RESOLVED_TENANT_HOSTNAME_VAL = ELECTRON_DOMAIN || hostname || '';
if (environment === 'development' && ENV_VARS.VITE_EMULATE_DOMAIN) {
  RESOLVED_TENANT_HOSTNAME_VAL = ENV_VARS.VITE_EMULATE_DOMAIN;
}

if (
  MODE === 'test' ||
  (ENV_VARS as fixMe).START_DEV ||
  typeof window === 'undefined'
) {
  RESOLVED_TENANT_HOSTNAME_VAL = 'localhost';
}
const IS_MERCATOR = MODE === 'development' && ENV_VARS.BASE_URL === '/lib/';

if (IS_MERCATOR || RESOLVED_TENANT_HOSTNAME_VAL === '127.0.0.1') {
  RESOLVED_TENANT_HOSTNAME_VAL = 'localhost';
}
export const RESOLVED_TENANT_HOSTNAME = RESOLVED_TENANT_HOSTNAME_VAL;

const rawConfig: Config | undefined = getConfigFromUrl(
  RESOLVED_TENANT_HOSTNAME
);

export const BASE_TENANT_HOSTNAME = rawConfig?.keycloakRealm;

if (!rawConfig) {
  throw new Error(
    `No config found for ${environment} with domain ${RESOLVED_TENANT_HOSTNAME}`
  );
}

export const config: Config = rawConfig;
const generateCrossAppConfigFromConfig = (config: Config): CrossAppConfig => pick(config, crossAppKeys);
/** Cross-app config is a config object with only the data that matters for booting a general Mastery frontend app, such as keycloak information. */
let rawCrossAppConfig: CrossAppConfig = generateCrossAppConfigFromConfig(config);

if (IS_CONNECT_CARRIER){
  rawCrossAppConfig = getConnectCarrierConfig(RESOLVED_TENANT_HOSTNAME);
}

export const crossAppConfig = rawCrossAppConfig;

try {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((window || {}) as any).masteryConfig = { ...config, BUILD_VERSION };
} catch {
  // noop
}

if (IS_ELECTRON && !config && sendSync('store.get', 'space')) {
  sendSync('store.delete', 'space');
  win.location.reload();
}
