import { TableId } from '@components/Table';
import { TablePreferencesSettings } from '@components/Table/util/customizeTable';
import { useFlagMe269599EnableTableCustomizationSettingsMigration } from '@generated/flags/ME-269599-enable-table-customization-settings-migration';
import { useCreateOrUpdateTablePreferenceMutation } from '@generated/mutations/createOrUpdateTablePreference';
import {
  TableFieldsInput,
  TablePreferenceResponseType,
} from '@generated/types';
import { useIndexedDBStore } from '@hooks/useIndexedDB';
import { TABLE_PREFERENCES_IDB_STORE } from '@hooks/useIndexedDB/config';
import { reportCustomSentryError } from '@utils/sentry';
import { atom, useAtom } from 'jotai';
import { isEmpty } from 'lodash-es';
import { addOrUpdateSinglePrefInIndexDB, convertPrefsToDbFormat } from './util';

export interface SingleTablePreference {
  tableId: string;
  tableName: string;
  tableFields: Partial<TablePreferencesSettings>[];
}

export type AllTablePreferences = Record<TableId, SingleTablePreference>;

interface TablePreferencesActions {
  /**
   * Update preferences for a single Table component.
   *
   * `tablePreferences`: Preferences for a single table. Includes the table's unique id.
   *
   * `isReset`: Are we resetting preferences? Used by the Customize dialog and column size reset.
   */
  setTablePrefs: (
    tablePreferences: SingleTablePreference,
    isReset?: boolean
  ) => void;
}

export interface TablePreferencesShape {
  objectId: string;
  tableName: string;
  columns: TablePreferencesSettings[];
}

// Use atoms to share state across all instances of this hook.
export const tablePrefsAtom = atom<AllTablePreferences>({});

/** useTablePreference hook */
export function useTablePreference(): [
  AllTablePreferences,
  TablePreferencesActions
] {
  const enableTableCustomizationMigration =
    useFlagMe269599EnableTableCustomizationSettingsMigration();

  const idb = useIndexedDBStore(TABLE_PREFERENCES_IDB_STORE);

  // Manage prefs in a global atom to share prefs across all instances of this hook.
  const [globalTablePrefs, setGlobalTablePrefs] = useAtom(tablePrefsAtom);

  // Mutation to create or update preferences for a single table.
  const [createOrUpdateTablePreferences] =
    useCreateOrUpdateTablePreferenceMutation({
      onCompleted: async (res) => {
        const updatedPrefs = res?.createOrUpdateTablePreference
          ? convertPrefsToDbFormat([
              res?.createOrUpdateTablePreference,
            ] as Partial<TablePreferenceResponseType>[])
          : [];

        if (enableTableCustomizationMigration && updatedPrefs?.length) {
          try {
            const pref = updatedPrefs[0] as TablePreferencesShape;
            await addOrUpdateSinglePrefInIndexDB(
              idb,
              pref,
              globalTablePrefs,
              (mergedPrefs: AllTablePreferences): void => {
                setGlobalTablePrefs((oldPrefs) => ({
                  ...oldPrefs,
                  ...mergedPrefs,
                }));
              }
            );
          } catch (error) {
            reportCustomSentryError(
              `Error while add Or Update table Preferences in InIndexDB: ${error}`
            );
          }
        }
      },
      onError: (error) => {
        reportCustomSentryError(
          `Error bulk update table preferences: ${error}`
        );
      },
    });

  // Called by Table component to trigger prefs update.
  const setTablePrefs = async (
    tablePreferences: SingleTablePreference,
    isReset?: boolean
  ): Promise<void> => {
    const { tableId, tableFields } = tablePreferences;

    if (isReset) {
      setGlobalTablePrefs((oldPrefs) => ({
        ...oldPrefs,
        [tableId]: tablePreferences,
      }));
    }

    try {
      if (!isEmpty(tableFields)) {
        await createOrUpdateTablePreferences({
          variables: {
            input: {
              objectId: tableId,
              tableName: tableId,
              tableFields: tableFields as TableFieldsInput[],
            },
          },
        });
      }
    } catch (error) {
      reportCustomSentryError(
        `Error while setting table preferences: ${error}`
      );
    }
  };

  return [globalTablePrefs, { setTablePrefs }];
}
